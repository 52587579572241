@import "../variables.scss";

.lockedOut-container {
  width: 100%;
  height: 49vw;
  z-index: 1;
  position: relative;
  //object-fit: Contain;
  margin-bottom: 0px;
  //margin: 0 0;
  //padding: 0;
  iframe {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 800px) {
  .lockedOut-container {
    margin-top: 20vw;
    iframe {
      width: 87%;
      height: 87%;
    }
  }
}
