@import "../variables.scss";

.video-container {
  z-index: 1;
  position: relative;
  //background-color: $primary-black;
  color: white;
  iframe {
    margin: 10px 10px;
    height: 22vw;
    width: 40vw;
    box-shadow: 3px 3px 2px $primary-blue, -2px -2px 2px $primary-pink;
  }
}
