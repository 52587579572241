@import "../variables.scss";

.merchandise-page {
  padding: 15px 15px 15px 15px;
  z-index: 1;
  // background-color: $primary-black;
  color: $primary-white;
  font-family: "Sunflower";
  position: relative;
  overflow: auto;

  h2 {
    margin-top: 12vh;
    margin-bottom: 0px;
    font-size: calc(5px + 3vw);
  }

  @media (max-width: 400px) {
    .merch-products {
      grid-template-columns: 1fr 1fr;
      //? We took this out bc we want images to be square in this grid
      // grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 5vw 5vw;
      padding: 5vh;
    }
  }

  @media (min-width: 401px) {
    .merch-products {
      grid-template-columns: 1fr 1fr 1fr;
      //? We took this out bc we want images to be square in this grid
      // grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 5vw 5vw;
      padding: 5vw;
    }
  }

  .merch-products {
    display: grid;

    .product {
      overflow: visible;
      box-shadow: 3px 3px 2px $primary-blue, -2px -2px 2px $primary-pink;
      background-color: $primary-black;
      a {
        text-decoration: none;
        color: $primary-white;
      }

      h3 {
        padding: 0;
        font-size: calc(5px + 1vw);
      }

      img {
        width: 100%;
        height: 100%;

        min-width: 100px;
        min-height: 100px;

        max-width: 600px;
        max-height: 600px;
      }
    }
  }
}
