@import "../variables.scss";

.lyrics {
  color: $primary-white;
  font-family: "Sunflower";
  z-index: 1;
  position: relative;
  display: block;
  height: auto;
  overflow: auto;
  font-size: 2vw;

  h1 {
    color: $primary-pink;
    margin-bottom: 5vw;
    font-size: 6vw;
  }

  h2 {
    color: #29abe2;
    margin-bottom: 0px;
    margin-top: 4vh;
  }

  p {
    margin-top: 0px;
  }

  br {
    display: inline;
  }

  br:after {
    content: "\a";
    white-space: pre;
  }

  a:link {
    color: $primary-white;
    text-decoration: none;
  }
  a:hover {
    color: $primary-white;
    text-decoration: none;
    border-bottom: 3px solid $primary-blue;
  }
  a:active {
    color: $primary-white;
    text-decoration: none;
  }
  a:visited {
    color: $primary-white;
    text-decoration: none;
  }
}
