@import "../variables.scss";

.ep-container {
  color: $primary-white;
  font-family: "Sunflower";
  z-index: 1;
  position: relative;
  display: flex;
  height: auto;
  overflow: auto;
  margin-top: 15vh;
  margin-left: 5vw;
  margin-right: 5vw;
  // background-color: $primary-black;
}

.ep-container img {
  //max-height: 100%;
  width: 40vw;
  height: 40vw;
  object-fit: contain;
  margin-right: 3vw;
  //box-shadow: 3px 3px 2px $primary-blue, -2px -2px 2px $primary-white;
  //clip-path: ellipse(200px 240px at 170px 200px);
  //margin-right: 30px;
}

.ep-container h2 {
  font-size: calc(5px + 3vw);
  text-align: left;
  margin-bottom: 20px;
  margin-top: 0px;
  // padding: 20px 20px;
}

.ep-container p {
  font-size: calc(5px + 1vw);
  text-align: left;
}

.ep-container #lyricsLink {
  font-size: calc(5px + 1vw);
  text-align: left;
}

// I was trying to format the link here but can't figure it out
li:link {
  float: right;
  margin-left: 4vw;
  font-size: calc(5px + 2vw);
  font-family: "Sunflower";
  color: $primary-blue;
  text-shadow: 2px 2px $primary-black;
  text-decoration: none;
}
li:visited {
  float: right;
  margin-left: 4vw;
  font-size: calc(5px + 2vw);
  font-family: "Sunflower";
  color: $primary-blue;
  text-shadow: 2px 2px $primary-black;
  text-decoration: none;
}

.ep-container .row {
  flex-direction: row;
  //justify-content: center;
}

.ep-container .row a {
  // font: bold 11px Arial;
  text-decoration: none;
  background-color: $primary-blue;
  color: $primary-white;
  padding: 2px 6px 2px 6px;
  border-top: 1px solid $primary-white;
  border-right: 1px solid $primary-white;
  border-bottom: 1px solid $primary-white;
  border-left: 1px solid $primary-white;
}

@media (max-width: 800px) {
  .ep-container {
    flex-direction: column;
  }
  .row {
    flex-direction: column;
    justify-content: center;
  }

  .ep-container img {
    margin-bottom: 5vw;
    //margin-right: 22vw;
    width: 94%;
    height: 94%;
    // display: flex;
    // object-fit: contain;
    // object-position: center;
    // justify-content: center;
  }
  .ep-container h2 {
    text-align: center;
  }
  .ep-container p {
    text-align: center;
    //padding: 12px 50px 12px 50px;
  }
}
