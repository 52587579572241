@import "../variables.scss";

.bio-container {
  color: $primary-white;
  font-family: "Sunflower";
  z-index: 1;
  display: flex;
  position: relative;
  height: auto;
  overflow: auto;
  margin-top: 10vw;
  margin-left: 5vw;
  margin-right: 5vw;
}
.bio-container img {
  width: 50vw;
  height: 33vw;
  object-fit: contain;
  //margin-bottom: 2vh;
  //margin-left: 3vw;
  // clip-path: ellipse(200px 240px at 170px 200px);
}

.bio-container h2 {
  font-size: calc(5px + 3vw);
  text-align: left;
  margin-bottom: 2vh;
  margin-top: 0px;
  //padding: 20px 20px;
}

.bio-container p {
  font-size: calc(5px + 1vw);
  text-align: left;
  margin-right: 2vw;
}

.bio-container .row {
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 800px) {
  .bio-container {
    flex-direction: column;
    .row {
      flex-direction: column;
    }
  }

  .bio-container img {
    width: 100%;
    height: 100%;
  }

  .bio-container h2 {
    margin-top: 0px;
    justify-content: center;
  }
  .bio-container p {
    justify-content: center;
    //padding: 12px 50px 12px 50px;
  }
}
