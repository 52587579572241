@import "../variables.scss";

.Logo-header {
  position: fixed;
  z-index: 2;
  opacity: 0.8;
  width: 20vw;
  height: 20vw;
  max-width: 275px;
  max-height: 275px;
  min-width: 60px;
  min-height: 60px;

  img {
    width: 100%;
    height: 100%;
  }
  img:hover {
    cursor: pointer;
  }
}
