@import "../variables.scss";

.social-container {
  padding: 3vh 0vw;
  width: 100%;
  margin: 0;
  display: inline-block;
}
a.social {
  margin: 0 1rem;
  transition: all 250ms ease;
  display: inline-block;
  filter: drop-shadow(2px 2px $primary-pink);
}

a.social:hover {
  transform: translateY(-2px);
  color: #48c8ff;
}

a.youtube {
  color: $primary-blue;
}

a.spotify {
  color: $primary-blue;
}

a.instagram {
  color: $primary-blue;
}

a.facebook {
  color: $primary-blue;
}
